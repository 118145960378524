import React from 'react';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';

import Router from 'routes';
import StoreRedux from 'store';
import ThemeProvider from 'theme';

import Loading from 'components/loading';
import ModalStatusCode from 'components/modalStatusCode';

function App() {
  const toasterOptions = {
    style: {
      fontWeight: 500,
      fontFamily: '"Inter", sans-serif',
    },
  };

  return (
    <Provider store={StoreRedux}>
      <ThemeProvider>
        <Router />
        <Loading />
        <ModalStatusCode />
        <Toaster position="top-right" toastOptions={toasterOptions} />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
