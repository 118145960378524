import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { LoadingButton } from '@mui/lab';
import { Box, IconButton, InputAdornment, Typography } from '@mui/material';

import { Form } from '@unform/web';

import { Creators as AuthActions } from 'store/ducks/auth';

import CustomTextField from 'components/forms/textField';

import * as Yup from 'yup';

function Login() {
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(false);

  function handleSubmitForm(formData) {
    setLoading(true);
    validateFields(formData);
  }

  async function validateFields(formData) {
    formRef.current.setErrors({});

    try {
      const schema = Yup.object().shape({
        mail: Yup.string().required('Este campo é obrigatório'),
        pass: Yup.string().required('Este campo é obrigatório'),
      });

      await schema.validate(formData, { abortEarly: false });

      signUserIn(formData);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
        setLoading(false);
      }
    }
  }

  function signUserIn(payload) {
    dispatch(AuthActions.signIn(payload));
    setLoading(false);
  }

  return (
    <>
      <Typography
        variant="h3"
        sx={{ fontSize: 28, fontWeight: 'bold', textAlign: 'center', mb: 1 }}
      >
        Bem vindo novamente!
      </Typography>

      <Typography sx={{ textAlign: 'center', mb: 3 }}>
        Acesse sua conta para <br />
        começar a controlar ...
      </Typography>

      <Form ref={formRef} onSubmit={handleSubmitForm}>
        <Box sx={{ mb: 3 }}>
          <CustomTextField
            name="mail"
            label="Seu email"
            labelProps={{
              variant: 'subtitle1',
              sx: { fontWeight: 'bold', mb: 0.5 },
            }}
          />
        </Box>

        <Box sx={{ mb: 6 }}>
          <CustomTextField
            type={showPass ? 'text' : 'password'}
            name="pass"
            label="Sua senha"
            labelProps={{
              variant: 'subtitle1',
              sx: { fontWeight: 'bold', mb: 0.5 },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPass((show) => !show)}>
                    {showPass ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={loading}
          sx={{ height: 50 }}
          disableElevation
          fullWidth
        >
          Entrar
        </LoadingButton>
      </Form>
    </>
  );
}

export default Login;
