import React, { useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from '@mui/material';

import { Form } from '@unform/web';

import { Creators as ProfessionalsActions } from 'store/ducks/professionals';

import CustomTextField from 'components/forms/textField';

import Mask from 'utils/mask';

import * as Yup from 'yup';

function CreateDialog({ handleClose }) {
  const dispatch = useDispatch();
  const formRef = useRef();

  const [image, setImage] = useState(null);
  const [presentation, setPresentation] = useState('');
  const [loading, setLoading] = useState(false);

  async function convetImage(e) {
    const file = e.target.files[0];
    const fileName = file && file.name;
    const ext = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();

    if (file.size > 1000000) {
      toast.error('Oops! Tamanho máximo é de 1 MB');
    } else {
      if (ext === 'jpeg' || ext === 'png' || ext === 'jpg') {
        setImage(await toBase64(file));
      } else {
        toast.error('Oops! Formato não é aceitável');
      }
    }
  }

  function toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function applyPhoneMask(e) {
    e.target.value = Mask(e.target.value, 'phone');
    return e.target.value;
  }

  function handleSubmitForm() {
    const formData = formRef.current?.getData();

    setLoading(true);
    validateFields(formData);
  }

  async function validateFields(formData) {
    formRef.current.setErrors({});

    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('Este campo é obrigatório'),
        specialty: Yup.string().required('Este campo é obrigatório.'),
        phone: Yup.string().required('Este campo é obrigatório'),
        presentation: Yup.string().required('Este campo é obrigatório'),
      });

      await schema.validate(formData, { abortEarly: false });

      if (!image) {
        toast.error('É obrigatório enviar uma imagem');
        setLoading(false);
        return;
      }

      mountData(formData);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
        setLoading(false);
      }
    }
  }

  function mountData(formData) {
    const payload = formData;
    payload.image = image;

    create(payload);
  }

  function create(payload) {
    dispatch(ProfessionalsActions.createProfessional(payload));
    handleClose();
  }

  return (
    <Dialog open fullWidth onClose={handleClose}>
      <DialogTitle sx={{ pb: 3 }}>
        <b>Criar profissional</b>
      </DialogTitle>

      <DialogContent>
        <Stack spacing={0.5} alignItems="center" sx={{ pb: 3 }}>
          <Box onClick={() => document.getElementById('icon-button-file').click()}>
            <Avatar src={image} sx={{ width: 65, height: 65, cursor: 'pointer' }}>
              <CameraAltIcon sx={{ fontSize: 28, color: 'background.paper' }} />
            </Avatar>

            <input
              type="file"
              accept="image/*"
              id="icon-button-file"
              style={{ display: 'none' }}
              onChange={convetImage}
            />
          </Box>

          <Typography variant="caption">Enviei uma boa imagem!</Typography>
        </Stack>

        <Form ref={formRef}>
          <Grid spacing={3} container>
            <Grid xs={12} item>
              <CustomTextField name="name" label="Nome" size="small" />
            </Grid>

            <Grid xs={12} md={6} item>
              <CustomTextField name="specialty" label="Especialidade" size="small" />
            </Grid>

            <Grid xs={12} md={6} item>
              <CustomTextField
                name="phone"
                label="Contato"
                size="small"
                onChange={applyPhoneMask}
              />
            </Grid>

            <Grid xs={12} item>
              <CustomTextField
                name="presentation"
                label="Apresentação do profissional"
                helperText={`${presentation.length}/300`}
                inputProps={{ maxLength: 300 }}
                rows={6}
                multiline
                onChange={(e) => setPresentation(e.target.value)}
              />
            </Grid>
          </Grid>
        </Form>
      </DialogContent>

      <DialogActions sx={{ '&:not(:first-of-type)': { ml: 2 } }}>
        <Button color="error" onClick={handleClose}>
          Cancelar
        </Button>

        <LoadingButton
          loading={loading}
          sx={{ borderRadius: '4px' }}
          disableElevation
          onClick={handleSubmitForm}
        >
          Criar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default CreateDialog;
