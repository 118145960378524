import { createReducer, createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  setStatusCode: ['statusCode'],
});

const INITIAL_STATE = {
  open: false,
  data: { status: '', code: '', message: '' },
};

export default createReducer(INITIAL_STATE, {
  [Types.SET_STATUS_CODE]: (_, { statusCode }) => statusCode,
});
