export default function mask(value, type) {
  if (!value) return '';

  value = value.replace(/\D/g, '');

  const fns = { phone: phoneMask(value) };

  return fns[type] || value;
}

const phoneMask = (value) => {
  if (value.length === 11) {
    value = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
  } else {
    value = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
  }

  if (!value[2]) {
    value = value[1];
  } else {
    value = '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');
  }

  return value;
};
