import React from 'react';
import { useSelector } from 'react-redux';

import { Box, CircularProgress } from '@mui/material';

function Loading() {
  const loadingState = useSelector(({ loading }) => loading);

  return (
    <Box
      display={loadingState ? 'flex' : 'none'}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: 'white',
        zIndex: 1301,
      }}
    >
      <CircularProgress size={40} />
    </Box>
  );
}

export default Loading;
