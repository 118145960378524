import React from 'react';
import { Outlet } from 'react-router-dom';

import { Container } from '@mui/material';

import Header from './header';

function Dashboard() {
  return (
    <>
      <Header />

      <Container component="section" maxWidth="lg" sx={{ my: 4, pb: 4 }}>
        <Outlet />
      </Container>
    </>
  );
}

export default Dashboard;
