import { toast } from 'react-hot-toast';

import { call, fork, put, takeLatest } from 'redux-saga/effects';

import { Creators as LoadingActions } from 'store/ducks/loading';
import { Creators as ProfessionalsActions, Types } from 'store/ducks/professionals';
import { Creators as StatusCodeActions } from 'store/ducks/statusCode';

import Api from 'services/api';

export function* getAllProfessionals() {
  yield put(LoadingActions.toggleLoading(true));

  try {
    const response = yield call(Api.get, '/professionals/all');
    const { data, message } = response.data;

    if (!data.length) {
      yield toast.error(message);
      return;
    }

    yield put(ProfessionalsActions.getAllProfessionalsSuccess(data));
  } catch ({ status, data: { code, message } }) {
    const error = { status, code, message };
    yield put(StatusCodeActions.setStatusCode({ open: true, data: error }));
  } finally {
    yield put(LoadingActions.toggleLoading(false));
  }
}

export function* getProfessional({ id }) {
  yield put(LoadingActions.toggleLoading(true));

  try {
    const response = yield call(Api.get, `/professionals/one?id=${id}`);
    const { data, message } = response.data;

    if (!Object.entries(data).length) {
      yield toast.error(message);
      return;
    }

    yield put(ProfessionalsActions.getProfessionalSuccess(data));
  } catch ({ status, data: { code, message } }) {
    const error = { status, code, message };
    yield put(StatusCodeActions.setStatusCode({ open: true, data: error }));
  } finally {
    yield put(LoadingActions.toggleLoading(false));
  }
}

export function* createProfessional({ payload }) {
  yield put(LoadingActions.toggleLoading(true));

  try {
    const response = yield call(Api.post, '/professionals', payload);
    const { message } = response.data;

    yield toast.success(message);
    yield fork(getAllProfessionals);
  } catch ({ status, data: { code, message } }) {
    const error = { status, code, message };
    yield put(StatusCodeActions.setStatusCode({ open: true, data: error }));
  } finally {
    yield put(LoadingActions.toggleLoading(false));
  }
}

export function* editProfessional({ payload }) {
  yield put(LoadingActions.toggleLoading(true));

  try {
    const response = yield call(Api.put, '/professionals', payload);
    const { message } = response.data;

    yield toast.success(message);
    yield fork(getAllProfessionals);
  } catch ({ status, data: { code, message } }) {
    const error = { status, code, message };
    yield put(StatusCodeActions.setStatusCode({ open: true, data: error }));
  } finally {
    yield put(LoadingActions.toggleLoading(false));
  }
}

export function* deleteProfessional({ id }) {
  yield put(LoadingActions.toggleLoading(true));

  try {
    const response = yield call(Api.delete, '/professionals', { id });
    const { message } = response.data;

    yield toast.success(message);
    yield fork(getAllProfessionals);
  } catch ({ status, data: { code, message } }) {
    const error = { status, code, message };
    yield put(StatusCodeActions.setStatusCode({ open: true, data: error }));
  } finally {
    yield put(LoadingActions.toggleLoading(false));
  }
}

export default function* all() {
  yield takeLatest(Types.GET_ALL_PROFESSIONALS, getAllProfessionals);
  yield takeLatest(Types.GET_PROFESSIONAL, getProfessional);
  yield takeLatest(Types.CREATE_PROFESSIONAL, createProfessional);
  yield takeLatest(Types.EDIT_PROFESSIONAL, editProfessional);
  yield takeLatest(Types.DELETE_PROFESSIONAL, deleteProfessional);
}
