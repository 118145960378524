function Palette() {
  return {
    common: { black: '#000', white: '#fff' },
    primary: {
      dark: '#400462',
      main: '#5c068c',
      light: '#7c37a3',
    },
    background: {
      paper: '#fff',
      default: '#f8f8f8',
    },
  };
}

export default Palette;
