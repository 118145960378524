import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { Avatar, Button, Card, Grid, Stack, Typography } from '@mui/material';

import { Creators as ProfessionalsActions } from 'store/ducks/professionals';

import CreateDialog from './create';
import EditDialog from './edit';

import { useTheme } from '@emotion/react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

function Professionals() {
  const { professionals } = useSelector(({ professionals }) => professionals);

  const dispatch = useDispatch();
  const mySwal = withReactContent(Swal);
  const theme = useTheme();

  const [create, setCreate] = useState(false);
  const [id, setId] = useState(null);

  useEffect(() => {
    dispatch(ProfessionalsActions.getAllProfessionals());
  }, []);

  function renderListProfessionals(professional, index) {
    return (
      <Grid key={index} xs={12} sm={6} lg={4} xl={3} item>
        <Card
          elevation={0}
          sx={{
            minHeight: 507,
            display: 'flex',
            flexDirection: 'column',
            p: 2,
            border: '1px solid #eef2f6',
            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            '&:hover': {
              borderColor: 'primary.light',
            },
          }}
        >
          <Grid direction="column" spacing={3} container>
            <Grid xs={12} item>
              <Avatar src={professional.photo} sx={{ width: 72, height: 72, margin: 'auto' }} />
            </Grid>

            <Grid xs={12} item>
              <Typography variant="h6" sx={{ fontWeight: 'bold', lineHeight: '1.167' }}>
                {professional.name}
              </Typography>

              <Typography variant="caption">{professional.specialty}</Typography>
            </Grid>

            <Grid xs={12} item>
              <Typography variant="subtitle2">{professional.presentation}</Typography>
            </Grid>

            <Grid xs={12} item>
              <Typography variant="caption">Phone</Typography>

              <Typography sx={{ fontSize: '0.75rem', mt: 0.5 }}>{professional.phone}</Typography>
            </Grid>
          </Grid>

          <Grid spacing={1} sx={{ flex: 1, alignItems: 'flex-end' }} container>
            <Grid xs={6} item>
              <Button
                variant="outlined"
                startIcon={<EditIcon />}
                color="info"
                sx={{ borderRadius: '4px', textTransform: 'none', fontWeight: 'bold' }}
                fullWidth
                disableElevation
                onClick={() => setId(professional.id)}
              >
                Editar
              </Button>
            </Grid>

            <Grid xs={6} item>
              <Button
                variant="outlined"
                startIcon={<DeleteOutlineIcon />}
                color="error"
                sx={{ borderRadius: '4px', textTransform: 'none', fontWeight: 'bold' }}
                fullWidth
                disableElevation
                onClick={() => handleDelete(professional.id)}
              >
                Excluir
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  }

  function handleDelete(id) {
    mySwal
      .fire({
        title: 'Tem certeza?',
        text: 'Você não será capaz de reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, apague!',
        confirmButtonColor: theme.palette.success.main,
        cancelButtonText: 'Não, cancele!',
        cancelButtonColor: theme.palette.error.main,
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(ProfessionalsActions.deleteProfessional(id));
        }
      });
  }

  return (
    <>
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          Profissionais
        </Typography>

        <Button
          variant="contained"
          startIcon={<AddIcon />}
          sx={{ minWidth: 150 }}
          disableElevation
          onClick={() => setCreate(true)}
        >
          Profissional
        </Button>
      </Stack>

      <Grid spacing={3} container>
        {professionals.map(renderListProfessionals)}
      </Grid>

      {create && <CreateDialog handleClose={() => setCreate(false)} />}

      {id && <EditDialog id={id} handleClose={() => setId(null)} />}
    </>
  );
}

export default Professionals;
