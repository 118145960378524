import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getAllProfessionals: null,
  getAllProfessionalsSuccess: ['professionals'],
  getProfessional: ['id'],
  getProfessionalSuccess: ['professional'],
  createProfessional: ['payload'],
  editProfessional: ['payload'],
  deleteProfessional: ['id'],
});

const INITIAL_STATE = {
  professionals: [],
  professional: {},
};

export default createReducer(INITIAL_STATE, {
  [Types.GET_ALL_PROFESSIONALS]: (state) => state,
  [Types.GET_ALL_PROFESSIONALS_SUCCESS]: (state, { professionals }) => ({
    ...state,
    professionals,
  }),
  [Types.GET_PROFESSIONAL]: (state) => state,
  [Types.GET_PROFESSIONAL_SUCCESS]: (state, { professional }) => ({ ...state, professional }),
  [Types.CREATE_PROFESSIONAL]: (state) => state,
  [Types.EDIT_PROFESSIONAL]: (state) => state,
  [Types.DELETE_PROFESSIONAL]: (state) => state,
});
