import { create } from 'apisauce';

const api = create({
  baseURL: 'https://us-central1-plurisaude-36df3.cloudfunctions.net/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

api.addAsyncRequestTransform((request) => async () => {
  const token = localStorage.getItem('session');

  if (token) request.headers.Authorization = `Bearer ${token}`;
});

api.addResponseTransform((response) => {
  if (!response.ok) throw response;
});

export default api;
