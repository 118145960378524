import { toast } from 'react-hot-toast';

import { call, takeLatest } from 'redux-saga/effects';

import { Types } from 'store/ducks/auth';

import Api from 'services/api';

export function* signIn({ payload }) {
  try {
    const response = yield call(Api.post, '/auth/sign-in', payload);
    const { token } = response.data;

    yield window.localStorage.setItem('session', token);

    yield (window.location.href = '/dash/funcionarios');
  } catch ({ data: { message } }) {
    toast.error(message);
  }
}

export default function* all() {
  yield takeLatest(Types.SIGN_IN, signIn);
}
