import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import InfoIcon from '@mui/icons-material/Info';
import { DialogActions, Button, Dialog, Typography, Stack, DialogContent } from '@mui/material';

import { Creators as StatusCodeActions } from 'store/ducks/statusCode';

function ModalStatusCode() {
  const { open, data } = useSelector(({ statusCode }) => statusCode);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleClickButton() {
    const statusCode = { open: false, data: { status: '', code: '', message: '' } };

    if (data.status === 400 || data.status === 500) {
      dispatch(StatusCodeActions.setStatusCode(statusCode));
    } else {
      dispatch(StatusCodeActions.setStatusCode(statusCode));
      navigate('/');
    }
  }

  return (
    <Dialog open={open}>
      <DialogContent>
        <Typography variant="h5" sx={{ mb: 3 }}>
          {data.status === 403 ? 'Tempo de sessão finalizado' : 'Ocorreu um erro inesperado'}
        </Typography>

        <Stack justifyContent="center" alignItems="center">
          <InfoIcon sx={{ color: '#4A4A4A', fontSize: 52 }} />

          <Typography sx={{ fontWeight: 'bold' }}>{data.code}</Typography>

          <Typography variant="caption" sx={{ p: 3, bgcolor: '#fafafa', mt: 2 }}>
            {data.message}
          </Typography>

          <Typography variant="body2" sx={{ mt: 3 }}>
            {data.status === 500
              ? 'Não foi possível completar a ação, informe o ocorrido ao suporte e tente novamente mais tarde.'
              : 'Esta ação não pode ser realizada agora, informe o ocorrido ao suporte junto com uma captura da tela e para maiores informações.'}
          </Typography>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ flexDirection: 'column', alignItems: 'flex-end', p: 3 }}>
        <Typography variant="caption" sx={{ mb: 1 }}>
          Algumas ações anteriores podem ser perdidas
        </Typography>

        <Button
          sx={{
            p: 0,
            color: 'primary.main',
            fontSize: 14,
            fontWeight: 500,
            textTransform: 'uppercase',
          }}
          onClick={handleClickButton}
        >
          {data.status === 500 || data.status === 400 ? 'Tentar novamente' : 'Ir para login'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalStatusCode;
