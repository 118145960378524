import React from 'react';
import { useRoutes } from 'react-router-dom';

import AuthLayout from 'layouts/auth';
import DashboardLayout from 'layouts/dashboard';

import LoginView from 'views/auth/login';

import ProfessionalsView from 'views/dashboard/professionals';

function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <AuthLayout />,
      children: [{ path: 'entrar', element: <LoginView /> }],
    },
    {
      path: '/dash',
      element: <DashboardLayout />,
      children: [{ path: 'funcionarios', element: <ProfessionalsView /> }],
    },
  ]);

  return routes;
}

export default Router;
