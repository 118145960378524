import React from 'react';

import { AppBar, Toolbar } from '@mui/material';

function Header() {
  return (
    <AppBar position="relative" sx={{ boxShadow: 'none', zIndex: 0 }}>
      <Toolbar sx={{ justifyContent: 'center', py: 1 }}>
        <img alt="" src="/images/logo.png" style={{ height: 70 }} />
      </Toolbar>
    </AppBar>
  );
}

export default Header;
