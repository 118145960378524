import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Card, Stack } from '@mui/material';

function Auth() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/entrar');
    }
  }, [location.pathname]);

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ width: '100%', height: '100%', pt: 2.5, pb: 4 }}
    >
      <Card elevation={1} sx={{ width: '100%', maxWidth: 490, py: 6, px: 7 }}>
        <Outlet />
      </Card>
    </Stack>
  );
}

export default Auth;
