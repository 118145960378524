import { combineReducers } from 'redux';

import AuthReducer from './auth';
import LoadingReducer from './loading';
import ProfessionalsReducer from './professionals';
import StatusCodeReducer from './statusCode';

export default combineReducers({
  auth: AuthReducer,
  professionals: ProfessionalsReducer,
  loading: LoadingReducer,
  statusCode: StatusCodeReducer,
});
