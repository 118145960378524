import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import RootReducer from './ducks';
import RootSagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

export default configureStore({
  reducer: RootReducer,
  middleware: (getDefaultMiddlewares) => [
    ...getDefaultMiddlewares({ serializableCheck: false }),
    sagaMiddleware,
  ],
});

RootSagas.map((saga) => sagaMiddleware.run(saga));
